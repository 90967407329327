<template>
  <div>
    <GameHistoryWidgets :widgets-data="widgets" />
    <b-card>
      <div class="row mb-2">
        <div class="col-12 col-md-3 mb-2">
          <h6>
            {{ `${$t('fields.result')} (${filteredList.length})` }}
          </h6>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <b-input-group>
            <b-form-input
              v-model="filterPlayerUsername"
              type="search"
              :placeholder="`${$t('fields.search')}... (${$t(
                'user.username'
              )})`"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <b-input-group class="ml-md-2">
            <b-form-input
              v-model="filterRoomId"
              type="search"
              :placeholder="`${$t('fields.search')}... (${$t(
                'games.room_id'
              )})`"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="col-12 col-md-3 mb-2">
          <FilterAgentsDropdown @update="(val) => filterAgent = val" />
        </div>
        <!-- </label> -->
      </div>
      <!-- Table -->
      <b-table
        :items="filteredList"
        :fields="fields"
        :busy="isFetchingGameHistory"
        responsive
        show-empty
      >
        <template #cell(id)="data">
          {{ `#${data.value}` }}
        </template>
        <template #cell(agentName)="data">
          <template v-if="
              data.item.agentCommissionDetail &&
              data.item.agentCommissionDetail.length > 0
            ">
            <span
              v-for="(agent, agentI) in data.item.agentCommissionDetail"
              :key="agentI"
            >
              <b-badge :id="`tooltip-${data.index}-${agentI}`">
                {{ agent.agentUsername }}
              </b-badge>
              <b-tooltip :target="`tooltip-${data.index}-${agentI}`">
                <b-link :to="`/agents/detail/${agent.agentId}`">
                  {{ agent.agentUsername }}
                </b-link>
                <p class="p-0 m-0">
                  <small class="mr-2">{{ $t('agent.commission') }}:</small>
                  {{ agent.commissionFee }}
                </p>
              </b-tooltip>
            </span>
          </template>
          <span v-else>
            {{ $t('agent.no_agent') }}
          </span>
        </template>
        <template #cell(waterFee)="data">
          {{ data.value | money }}
          {{ data.item.waterFeeType === 'PERCENT' ? '%' : '฿' }}
        </template>
        <template #cell(winAmount)="data">
          <span v-if="+data.value > 0">
            {{ data.value | currency }}
          </span>
          <span v-else>
            {{ $t('bots.name') }}
          </span>
        </template>
        <template #cell(type)="">
          <b-badge variant="light">
            {{ 'มินิเกม' }}
          </b-badge>
        </template>
        <template #cell(gameType)="data">
          {{ displayGameResult(data.item) }}
        </template>

        <template #cell(numberOfPlayersInRoom)="data">
          <span
            v-for="(player, index) in filteredPlayers(data.item)"
            :key="index"
          >
            <b-avatar
              :id="`player-${player.playerId}`"
              :key="index"
              :size="24"
              variant="info"
            />
            <b-tooltip
              :target="`player-${player.playerId}`"
              triggers="hover"
            >
              <b-link :to="`/players/detail/${player.playerId}`">
                {{ player.playerName || '-' }}
              </b-link>
            </b-tooltip>
          </span>
        </template>
        <template #cell(gameId)="data">
          {{ displayWinnerName(data.item) }}
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(roomStatus)="data">
          <b-badge
            v-if="data.value === 'FINISHED'"
            variant="success"
          >
            {{ $t('utils.status.finished') }}
          </b-badge>
          <b-badge
            v-else-if="data.value === 'WAITING'"
            variant="warning"
          >
            {{ $t('utils.status.waiting') }}
          </b-badge>
          <b-badge
            v-else-if="data.value === 'CANCELED'"
            variant="danger"
          >
            {{ $t('utils.status.canceled') }}
          </b-badge>
          <b-badge
            v-else
            variant="light"
          >
            {{ data.value }}
          </b-badge>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
      </b-table>
      <!-- pagination -->
      <PaginationInput :per-page="limit" :total="gameScore.totalCount" @update="(val) => page = val"/>

    </b-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'MiniGameHistory',
  components: {
    GameHistoryWidgets: () => import('../history-widgets'),
    FilterAgentsDropdown: () => import('../../agents/filter-agents-dropdown'),
  },
  props: {
    gameSlug: {
      type: Number,
      required: true,
      default: 0,
    },
    selectedDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      limit: 50,
      page: 1,
      filterAgent: '',
      filterRoomId: '',
      filterPlayerUsername: '',
      fields: [
        {
          key: 'id',
          label: '#',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'agentName',
          label: `${this.$t('agent.name')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'gameType',
          label: `${this.$t('games.game_result')}`,
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'bet',
          label: `${this.$t('games.bet_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'winAmount',
          label: `${this.$t('games.win_amount')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'type',
          label: 'ประเภท',
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'waterFee',
          label: `${this.$t('games.water_rate')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'numberOfPlayersInRoom',
          label: `${this.$t('games.player')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'gameId',
          label: `${this.$t('games.winner')}`,
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'roomStatus',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingGameHistory: (state) => state.game.isFetchingGameScore,
    }),
    ...mapGetters(['gameScore']),
    filteredList() {
      return this.gameScore.items || []
    },
    widgets() {
      const { totalCount, allBet, drinkWater } = this.gameScore
      return [
        {
          icon: 'activity',
          name: `${this.$t('pages.mini_games.total_games')}`,
          value: totalCount,
        },
        {
          icon: 'dollar-sign',
          name: `${this.$t('pages.mini_games.total_bet')}`,
          value: allBet,
        },
        {
          icon: 'coffee',
          name: `${this.$t('pages.mini_games.total_fee')}`,
          value: drinkWater,
        },
      ]
    },
  },
  watch: {
    selectedDate(val) {
      if (val) {
        this.fetchData()
      }
    },
    gameSlug(val) {
      if (val) {
        this.fetchData()
      }
    },
    page(val) {
      if (val) {
        this.fetchData()
      }
    },
    filterAgent(val) {
      if (val) {
        this.fetchData()
      }
    },
    filterRoomId() {
      this.fetchDataByRoomId()
    },
    filterPlayerUsername() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchGameScore']),
    fetchData() {
      const date = dayjs(this.selectedDate || new Date()).format('YYYY-MM-DD')
      this.fetchGameScore({
        slug: this.gameSlug,
        date,
        page: this.page,
        limit: this.limit,
        agentUsername: this.filterAgent,
        roomId: this.filterRoomId,
        username: this.filterPlayerUsername,
      })
    },
    fetchDataByRoomId() {
      if (this.filterRoomId.length > 2) {
        this.fetchGameScore({
          slug: this.gameSlug,
          date: '',
          page: this.page,
          limit: this.limit,
          roomId: this.filterRoomId,
          username: this.filterPlayerUsername,
        })
      } else if (this.filterRoomId.length < 1) {
        this.fetchData()
      }
    },
    displayGameResult(game) {
      const { gameType } = game
      let gameDetail = { gameResult: '' }
      if (gameType === 'HEAD_TAIL') {
        gameDetail = game.headTailGameDetail
      } else if (gameType === 'ROCK_PAPER_SCISSORS') {
        gameDetail = game.rockPaperScissorsGameDetail
      } else if (gameType === 'HI_LO') {
        gameDetail = game.hiLoGameDetail
      }
      return gameDetail.gameResult
        ? this.$t(`games.result.${gameDetail.gameResult.toLowerCase()}`)
        : ''
    },
    filteredPlayers(game) {
      const { gameType } = game
      let gameDetail = {
        playerOneId: '',
        playerOneName: '',
        playerTwoId: '',
        playerTwoName: '',
      }
      if (gameType === 'HEAD_TAIL') {
        gameDetail = game.headTailGameDetail
      } else if (gameType === 'ROCK_PAPER_SCISSORS') {
        gameDetail = game.rockPaperScissorsGameDetail
      } else if (gameType === 'HI_LO') {
        gameDetail = game.hiLoGameDetail
      }
      return [
        {
          playerId: gameDetail.playerOneId,
          playerName: gameDetail.playerOneName,
        },
        {
          playerId: gameDetail.playerTwoId,
          playerName: gameDetail.playerTwoName,
        },
      ]
    },
    displayWinnerName(game) {
      const { gameType } = game
      if (gameType === 'CARD_BATTLE') {
        return game.cardBattleGameDetail.winnerPlayerName
      } else {
        let gameDetail = {}
        if (gameType === 'HEAD_TAIL') {
          gameDetail = game.headTailGameDetail
        } else if (gameType === 'ROCK_PAPER_SCISSORS') {
          gameDetail = game.rockPaperScissorsGameDetail
        } else if (gameType === 'HI_LO') {
          gameDetail = game.hiLoGameDetail
        }
        return gameDetail.winnerPlayerId &&
          gameDetail.winnerPlayerId === gameDetail.playerOneId
          ? gameDetail.playerOneName
          : gameDetail.playerTwoName
      }
    },
  },
}
</script>